<template>
  <div>
    <div class="wrapper">
      <ul
        :title="
          $getVisibleNames(
            'mesh.methodologicalstrategy',
            true,
            'Estrategias Metodológicas'
          )
        "
        class="ml-4"
      >
        <li
          v-for="strategy in selectedMethodologicalStrategies"
          :key="strategy.id"
          class="text-left"
        >
          {{ strategy.name }}
        </li>
      </ul>
    </div>
    <div v-if="allows_crud" class="text-center">
      <ButtonSmall
        :tooltip_text="`Editar ${$getVisibleNames(
          'mesh.methodologicalstrategy',
          true,
          'Estrategias Metodológicas'
        )}`"
        :click_button="
          () => $bvModal.show(`modal-methodological-strategies-${eCMacro_id}`)
        "
        :class="``"
        :icon="'square'"
      ></ButtonSmall>
      <!-- <button-edit
        @click="$bvModal.show(`modal-methodological-strategies-${eCMacro_id}`)"
        v-b-tooltip.v-secondary.noninteractive="
          `Editar ${$getVisibleNames(
            'mesh.methodologicalstrategy',
            true,
            'Estrategias Metodológicas'
          )}`
        "
      ></button-edit> -->
    </div>
    <b-modal
      :title="`Seleccionar ${$getVisibleNames(
        'mesh.methodologicalstrategy',
        true,
        'Estrategias Metodológicas'
      )}`"
      :id="`modal-methodological-strategies-${eCMacro_id}`"
      size="lg"
      @hidden="closeMethodologicalStrategies(eCMacro_id)"
    >
      <template v-slot:modal-header>
        <h5 class="custom-modal-title">
          Seleccionar
          {{
            $getVisibleNames(
              "mesh.methodologicalstrategy",
              false,
              "Estrategia Metodológica"
            )
          }}
        </h5>
        <InfoTooltip
          class="selection-info ml-0"
          tooltip_text="Para seleccionar múltiples elementos, mantenga presionada la tecla Ctrl y a continuación, seleccione los elementos que desee."
        >
        </InfoTooltip>
      </template>
      <b-input-group>
        <b-form-input
          v-model="input_search"
          type="search"
          size="sm"
          :placeholder="`Buscar ${$getVisibleNames(
            'mesh.methodologicalstrategy',
            true,
            'Estrategias Metodológicas'
          )}...`"
        >
        </b-form-input>
        <b-input-group-append>
          <b-button
            :disabled="!input_search"
            @click="input_search = ''"
            size="sm"
            >Limpiar</b-button
          >
        </b-input-group-append>
      </b-input-group>
      <small class="ml-1">
        Se muestran
        <span style="color: var(--kl-thirth-hover-color)">coloreadas</span>
        las
        {{
          $getVisibleNames(
            "mesh.methodologicalstrategy",
            true,
            "Estrategias Metodológicas"
          )
        }}
        definidas como mínimas para la
        {{ $getVisibleNames("mesh.egressprofilematter", false, "Asignatura") }}.
      </small>
      <b-form-select
        v-model="methodological_strategies"
        text-field="name"
        value-field="id"
        multiple
        :select-size="leftMethodologicalStrategies.length"
      >
        <b-form-select-option
          v-for="meth in matterMethodologicalStrategies"
          :value="meth.id"
          :key="meth.id"
          style="background-color: var(--kl-thirth-hover-color)"
          :title="`Estratégias Mínimas de la ${$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )}`"
          >{{ meth.name }}</b-form-select-option
        >
        <b-form-select-option
          v-for="meth in searchMethodologicalStrategies"
          :value="meth.id"
          :key="meth.id"
          >{{ meth.name }}</b-form-select-option
        >
      </b-form-select>
      <template v-slot:modal-footer>
        <b-button
          class="btn-cancel"
          size="sm"
          @click="closeMethodologicalStrategies(eCMacro_id)"
          >Cancelar</b-button
        >
        <b-button size="sm" @click="updateMethodologicalStrategies(eCMacro_id)"
          >Guardar</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "MethodologicalStrategiesRow",
  components: {
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
  },
  props: {
    eCMacro_id: {
      type: Number,
      required: true,
    },
    matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      methodological_strategies: [],
      input_search: "",
    };
  },
  computed: {
    ...mapGetters({
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      methodologicalStrategies: names.METHODOLOGICAL_STRATEGIES,
      matters: names.MATTERS,
    }),
    eCMacro() {
      return this.evaluationCriteriasMacros.find(
        (x) => x.id == this.eCMacro_id
      );
    },
    matter() {
      return this.matters.find((x) => x.id == this.matter_id);
    },
    selectedMethodologicalStrategies() {
      let list = [];
      if (!this.eCMacro) return list;
      this.eCMacro.methodological_strategies.forEach((element) => {
        let instance = this.methodologicalStrategies.find(
          (x) => x.id == element
        );
        if (instance) list.push(instance);
      });
      return list;
    },
    matterMethodologicalStrategies() {
      let list = [];
      if (!this.matter) return list;
      this.matter.methodological_strategies.forEach((element) => {
        let instance = this.methodologicalStrategies.find(
          (x) => x.id == element
        );
        if (instance) list.push(instance);
      });
      return list;
    },
    leftMethodologicalStrategies() {
      let list = this.methodologicalStrategies.filter(
        (x) =>
          (x.is_active || this.methodological_strategies.includes(x.id)) &&
          !this.matter.methodological_strategies.includes(x.id)
      );
      return list.sort(function (a, b) {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    },
    searchMethodologicalStrategies: function () {
      return this.leftMethodologicalStrategies
        .filter((item) => {
          return (
            item.name.toLowerCase().includes(this.input_search.toLowerCase()) ||
            this.methodological_strategies.includes(item.id)
          );
        })
        .sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
  },
  methods: {
    updateMethodologicalStrategies(evaluation_criteria_macro_id) {
      let payload = {
        id: evaluation_criteria_macro_id,
        item: {
          methodological_strategies: this.methodological_strategies,
        },
      };
      this.$store
        .dispatch(names.PATCH_EVALUATION_CRITERIA_MACRO, payload)
        .then((response) => {
          if (response)
            this.$bvModal.hide(
              `modal-methodological-strategies-${evaluation_criteria_macro_id}`
            );
        });
    },
    closeMethodologicalStrategies(evaluation_criteria_macro_id) {
      this.methodological_strategies = this.eCMacro.methodological_strategies;
      this.$bvModal.hide(
        `modal-methodological-strategies-${evaluation_criteria_macro_id}`
      );
    },
  },
  // created() {},
  watch: {
    eCMacro: function () {
      this.methodological_strategies = this.eCMacro.methodological_strategies;
    },
  },
  mounted() {
    if (this.eCMacro)
      this.methodological_strategies = this.eCMacro.methodological_strategies;
  },
};
</script>

<style scoped>
select option:checked {
  font-weight: bold !important;
  /* background-color: #cecece !important; */
}
</style>